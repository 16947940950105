import logo from './logo.svg';
import './App.css';
import Register from './Components/Register';
import { BrowserRouter, Route, Switch, Routes } from 'react-router-dom';
import Sidebar from './Components/sidebar';
import Login from './Components/Login';
import RootGaurd from './Components/RootGaurd'

function App() {
  return (
    <div className="data-header">   
    <BrowserRouter>
      <RootGaurd>
          <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<Sidebar />} />
            </Routes>
        </RootGaurd>
      </BrowserRouter>
     
    </div>
  );
}

export default App;

{/* <div className="data-header">   
    <BrowserRouter>
      <RootGaurd>
          <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<Sidebar />} />
            </Routes>
        </RootGaurd>
      </BrowserRouter>
     
    </div> */}
