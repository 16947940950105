import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import Register from './Register';
import Dashboard from './Dashboard';
import BoomDashboard from './boomDashboard';
import EntryExit from './EntryExit';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from "react-router-dom"
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
const image = require('../Assets/Cereberum.JPG')
const drawerWidth = 240;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  backgroundColor: `white`
};


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menu, setMenu]  = React.useState(0);
  const [openPop, setOpenPop] = React.useState(false);
  const history = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSideClick = (e) => {
    console.log("E", e)
    setMenu(e);
    if(e == 3){
      setOpenPop(true)
    }
  };

  const handlePopUp = () => {
    setOpenPop(false)
    localStorage.removeItem('Session');
    history("/")
  };

  const handlePopClose = () => {
    setOpenPop(false)
    handleSideClick(0);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      <Drawer variant="permanent" open={open}
       PaperProps={{
        sx: {
        backgroundColor: "#5260ff",
        color: "white",
        marginRight: 5,
        }
    }} >
       <DrawerHeader>
        {!open&&(
            <>
            <List>
                <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                //marginRight: 5,
                ...(open && { display: 'none' }),
                }}
            >
                <MenuIcon />
            </IconButton>
            
            </List>
            </>
            
        )}
        {open &&(
            <>
             <img src={image} width='85%' />
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            </>
        )}
           
        </DrawerHeader>
        <Divider />
        <List>
         
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleSideClick(0)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 5 : 'auto',
                    justifyContent: 'center',
                    
                  }}
                >
                   <DashboardIcon style={{color: `white`}}/>
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleSideClick(1)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 5 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                    <BrunchDiningIcon  style={{color: `white`}}/>
                </ListItemIcon>
                <ListItemText primary={"Entry/Exit Log"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleSideClick(2)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 5 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                   <AddTaskIcon style={{color: `white`}}/>
                </ListItemIcon>
                <ListItemText primary={"Register"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Typography paragraph>
        <Grid container spacing={12}>
        <Grid item xs={8} style={{ paddingTop: `5%` }}>
          <h1 style={{ paddingLeft: `10px`, marginTop: `-6%` }}>Windy Heights</h1>
        </Grid>
        <Grid item xs={4} style={{ paddingTop: `2%` }}>
          <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={10} style={{ marginRight: `-35%`}}>
                <img src={image} width='50%'/>
              </Grid>
              <Grid item xs={2} style={{ paddingTop: `4%` }}>
                <PowerSettingsNewIcon  style={{marginTop: `-6%` }} 
                onClick={() => handleSideClick(3)}/>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
        
        {menu == 0 &&(
            <Dashboard /> //<BoomDashboard /> <Dashboard />
         )}
         {menu == 1 &&(
           <EntryExit />
         )}
         {menu == 2 &&(
          <Register />
         )}
        </Typography>
      </Box>
      <Modal
        open={openPop}
        onClose={handlePopClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  sx={style} style={{width:`25%`}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Attention
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you Sure you want to logout
          </Typography>
          <Grid container   className="Button">
                        <Grid Item xs={12} md={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePopUp}
                            style={{marginRight: `15px`}}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            //color="#F06363"
                            onClick={handlePopClose}
                            style={{backgroundColor:`#F06363`}}
                        >
                            No
                        </Button>
                        </Grid>
                    </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
