import React, { useState, useEffect }from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import '../App.css';
import Grid from '@mui/material/Grid';
import  DataServices  from '../Api/DataServices'
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const image = require('../Assets/Cereberum.JPG')

export default function Login() {
    const navigate = useNavigate();
    const [userName , setUserName] = useState('')
    const [password , setPassword] = useState('')
    const [open, setOpen] = useState(false)
    const [values , setValues] = useState(false)

    const handleSubmit = () => {
        let data = {
            "emailId": userName,
            "password": password
          }
        DataServices.masterLogin(data).then(d => {   
            setOpen(true)  
            localStorage.setItem('Session', true);
            navigate("/home");   
         });
    }

    const handleSnackClose =()=>{
        setOpen(false)
     }

    const handleChange = (e) => {
        setUserName(e.target.value)
   };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    };

    const handleClickShowPassword = () => {
        setValues(!values);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    return (
        <>
           <div className="App">     
           <div className="App-header">
                <div className="Login" style={{width: `35%`}}>
                <Grid container spacing={5} >
                    <Grid item xs={12} md={12}>
                        <img src={image}  />
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <TextField
                    id="name"
                    label="Email"
                    //placeholder="Placeholder"
                    multiline
                    type="text"
                    onChange={handleChange} 
                    value={userName}
                    style={{width: `100%`}}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    {/* <TextField
                    id="password"
                    label="Password"
                    //placeholder="Placeholder"
                    multiline
                    type="password"
                    onChange={handlePasswordChange} 
                    value={password}
                    style={{width: `100%`}}
                    /> */}
        <FormControl  variant="outlined" style={{width: `100%`}}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={'password'}
            value={password}
            onChange={handlePasswordChange}
            endAdornment={
              <InputAdornment position="end">
                {/* <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values ? <VisibilityOff /> : <Visibility />}
                </IconButton> */}
              </InputAdornment>
            }
            label="Password"
            
          />
        </FormControl>
                </Grid> 
                    <Grid container   className="Button">
                        <Grid Item xs={12} md={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            style={{marginRight: `15px`}}
                        >
                            Login
                        </Button>
                        <Button
                            variant="contained"
                            //color="#F06363"
                            onClick={handleSubmit}
                            style={{backgroundColor:`#F06363`}}
                        >
                            Clear
                        </Button>
                        </Grid>
                    </Grid>
                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                       // message={"Login Successfully"}
                        //action={action}
                        severity="success"
                    >
                        <Alert onClose={handleSnackClose} severity="info" sx={{ width: '100%' }}>
                        Login Successfully
                        </Alert>
                            </Snackbar>

                    </Grid> 
                    

                </div>
                </div>
            </div>
        </>
    );
}

